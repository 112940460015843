import React from 'react';
import { Container } from 'react-bootstrap';

function Community() {
    return (
        <Container className="main">
            <Container className="page">
                <h1>Community</h1>
                <article>
                </article>
            </Container>
        </Container>
    );
}

export default Community;
