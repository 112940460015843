import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function Opportunities() {
    const emsRef = useRef(null);
    const fireRef = useRef(null);

    const hash = useLocation().hash;

    useEffect(() => {
        if (hash && hash.indexOf('ems') > -1 && emsRef && emsRef.current) {
            scrollToRef(emsRef);
        }
    }, [hash, emsRef]);

    useEffect(() => {
        if (hash && hash.indexOf('fire') > -1 && fireRef && fireRef.current) {
            scrollToRef(fireRef);
        }
    }, [hash, fireRef]);

    // scrollToHash();
    return (
        <Container className="main">
            <Container className="page">
                <Breadcrumbs />
                <Container>
                    <Row>
                        <Col md={9}>
                        <h1>Opportunities</h1>

                        <section className="mt-5">
                            <h4 ref={fireRef}>Employment</h4>
                            <p>
                                Central Mason Fire & EMS maintains an on-going eligibility list. Department applications are also required. <Link to="/opportunities/employment">Learn more...</Link>
                            </p>
                        </section>
                        
                        {/* <hr className="m-5" /> */}

                        <section className="mt-5 mb-5">
                            <h4 ref={fireRef}>Volunteer</h4>
                            <p>
                                Become an asset to your family, friends and community by volunteering. Being a volunteer fire fighter is very rewarding. <Link to="/opportunities/volunteer">Learn more...</Link>
                            </p>
                        </section>
                    </Col>
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                </Row>
                </Container>
            </Container>
        </Container>
    );
}

export default Opportunities;
