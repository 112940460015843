import React, { useContext } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { AppContext } from "../AppContext";
// import Content from "./home/Content";
import ContentCard from "./home/ContentCard";
import { NavLink } from "react-router-dom";

function Contents({ mainPostId }) {
  const { contentData, contentsAreLoading } = useContext(AppContext);

  if (contentsAreLoading) {
    return (
      <Container fluid id="news3" className="g-0">
        <Row className="band mb-2 text-center">
          <Col></Col>
          <Col>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  if (!contentData) return null;

  const lastThree = [];
  let postCounter = 0;
  for (let i = 0; i < contentData.length; i += 1) {
    if (contentData[i] && `${contentData[i].id}`.indexOf(mainPostId) === -1) {
      lastThree.push(contentData[i]);
      postCounter += 1;
      if (postCounter >= 3) {
        break;
      }
    }
  }

  if (lastThree.length <= 0) {
    return null;
  }

  return (
    <Container id="news3" className="mb-1" style={{ borderRadius: "0" }}>
      <Card>
        <Card.Header>
          <h5 className="mb-0">Latest from Central Mason Fire & EMS</h5>
        </Card.Header>
        <Card.Body>
          {lastThree.map((content, index) => (
            // <Content content={content} key={index} />
            <ContentCard content={content} key={index} />
          ))}
          {/* <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
          With supporting text below as a natural lead-in to additional content.
        </Card.Text> */}
          {/* <Button variant="primary">Go somewhere</Button> */}
          <div className="text-center">
            <Button variant="dark" as={NavLink} to="/contents" size="sm">
              View more from CMFE...
            </Button>
            {/* {` or `} 
          <Button variant="dark" as={NavLink} to="/about/pressreleases" size="sm">
            View press releases...
          </Button> */}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Contents;
