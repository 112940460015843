import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from './Breadcrumbs';
import { AppContext } from '../../AppContext';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function FireLevy() {
    const { pages, pagesAreLoading } = useContext(AppContext);
    const [fireLevy, setFireLevy] = useState({});

    useEffect(() => {
        const getFireLevyPage = () => {
          if (pages) {
            let fireLevyPage = {};
            pages.forEach((page, index) => {
              if (page && page.link.indexOf("fire-levy") > -1 && page.link.indexOf("memo-from-the-fire-chiefs") === -1) {
                fireLevyPage = page;
              }
            });
            setFireLevy(fireLevyPage);
          }
        };
    
        if (pages) {
            getFireLevyPage();
        }
      }, [pages]);
    
      if (pagesAreLoading) {
        return (
          <>
            <Container id="fire-levy" className="main">
              <ScrollToTopOnMount />
              <Container className="page">
                <Breadcrumbs activePage="Fire District #5 Fire Levy" />
                <Row>
                  <Col md={12}>
                    <Container>
                      <h1>Fire District #5 Fire Levy</h1>
                      <Container className="main align-content-start">
                        <Container>
                          <div className="d-flex justify-content-center mt-5 mb-3">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </Container>
                      </Container>
                    </Container>
                  </Col>
                </Row>
              </Container>
              <GoToTop />
            </Container>
          </>
        );
      }
    
      if (!pages) return null;

      return (
        <>
          <Container id="fire-levy" className="main">
            <ScrollToTopOnMount />
            <Container className="page">
              <Breadcrumbs activePage="Fire District #5 Fire Levy" />
              <Row>
                <Col md={12}>
                  <Container>
                    <h1>Fire District #5 Fire Levy</h1>
                    {fireLevy &&
                    fireLevy.content &&
                    fireLevy.content.rendered ? (
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: fireLevy.content.rendered,
                        }}
                      />
                    ) : null}
                  </Container>
                </Col>
              </Row>
            </Container>
            <GoToTop />
          </Container>
        </>
      );
}

export default FireLevy;
