import React, { useState, useEffect } from 'react';
import { Image, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

function LatestNews({ post }) {
    const [media, setMedia] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (post && post.featured_media) {
            const mediaUrl = `https://cmfe.org/blog/wp-json/wp/v2/media/${post.featured_media}`;
            axios.get(mediaUrl)
        .then((response) => {
                if (response.data && response.data.source_url) {
                    setMedia(response.data.source_url);
                }
            })
            .finally(() => {
                setLoading(false);
        });
        } else {
            setLoading(false);
        }
    }, [post]);

    if (!post) return null;

    const pathname = `/latest/${post.id}`;
    return (
        <Col xs={12} md={4}>
            <NavLink to={{ pathname }} className="card">
                { loading ? (
                    <div className="d-flex justify-content-center mt-5 mb-5">
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    </div>
                ) : (
                    media ? <Image
                        src={media}
                        thumbnail
                        width={250}
                        height={250}
                        className="mx-auto d-block mb-3"
                    /> : null
                ) }
                <article className="card-body">
                    <h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                    <p
                        className="card-textmt-3 font-smaller"
                        dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}
                    />
                    <span>{moment(post.modified).format("MMMM D YYYY")}</span>
                </article>
            </NavLink>
        </Col>
    );
}

export default LatestNews;
