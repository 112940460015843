import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppContext } from '../AppContext';
import LatestNews from './home/LatestNews';

function News({ mainPostId }) {
    const { posts, postsAreLoading } = useContext(AppContext);
    
    if (postsAreLoading) {
        return (
            <Container fluid id="news3">
                <Row className="band mb-2 text-center">
                    <Col></Col>
                    <Col>
                        <div className="d-flex justify-content-center mt-5 mb-5">
                            <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        );
    }
    

    if (!posts) return null;

    const lastThree = [];
    let postCounter = 0;
    for (let i=0; i < posts.length; i+=1) {
        if (posts[i] && `${posts[i].id}`.indexOf(mainPostId) === -1) {
            lastThree.push(posts[i]);
            postCounter += 1;
            if (postCounter >= 3) {
                break;
            }
        }
    }

    if (lastThree.length <= 0) {
        return null;
    }

    return (
        <Container id="news3" className="mb-4" style={{ borderRadius: '0' }}>
            <Row className="band mb-2">
                {lastThree.map((post, index) => (<LatestNews post={post} key={index} />))}
            </Row>
        </Container>
    );
}

export default News;
