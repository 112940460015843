import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Sidebar from '../about/Sidebar';
import Breadcrumbs from '../about/Breadcrumbs';
import VolunteerDynamic from '../dynamic/VolunteerDynamic';

function Volunteer() {
    return (
        <Container className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs activePage="Volunteer" />
                <Container>
                    <Row>
                        <Col md={9}>
                            <h1>Volunteer</h1>
                            <VolunteerDynamic />
                        </Col>
                        <Col md={3}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <GoToTop />
        </Container>
    );
}



export default Volunteer;
