import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

function Post({ content }) {
    const [authorName, setAuthorName] = useState('');

    useEffect(() => {
        async function loadData() {
            if (content) {
                let author;

                const authorId = content.author;
                const authorUrl = `https://cmfe.org/blog/wp-json/wp/v2/users/${authorId}`;
                const getAuthor = axios(authorUrl);

                axios.all([getAuthor])
                .then(axios.spread((...responses) => {
                    const authorResponse = responses[0];
                    author = authorResponse.data;
                    setAuthorName(author.name);
                })).catch(errors => {
                    console.log(errors);
                });
            }
        }
    
        loadData();
    }, [content]);

    if (!content) return null;

    const pathname = `/contents/${content.id}`;

    return (
        <>
            <NavLink to={{ pathname }}><h1 dangerouslySetInnerHTML={{__html: content.title.rendered}} /></NavLink>
            <div className="d-flex">
                <article style={{ width: "100%", overflow: "auto" }}>
                    <div className="post-meta d-flex">
                        { authorName? <small className="me-2 author text-secondary"><strong>Author:</strong> {authorName}</small> : null }
                        { content.date ? <small className="me-2 date text-secondary"><strong>Published:</strong> {moment(content.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</small> : null }
                    </div>
                    <div
                        className="post-content mt-3"
                        dangerouslySetInnerHTML={{__html: content.content.rendered}}
                    />
                </article>
            </div>
        </>
    );
}

export default Post;
