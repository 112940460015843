import React from 'react';
import { Container, Tab, Nav, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Minutes from './Minutes';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import AgendasAndMinutes from './AgendasAndMinutes';

function MinutesHome() {
    return (
        <Container className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs activePage="Agendas & Minutes" />
                <Container>
                    <Row>
                        <Col md={9}>
                            <h1>Agendas & Minutes</h1>
                            <Container className="mb-5">

                                <AgendasAndMinutes />

                                <hr className="m-4" />

                            </Container>
                            <Container>
                                <h4 className="text-center mb-3">MEETING MINUTES</h4>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="2024">
                                    <Row>
                                        <Col sm={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="2024">
                                                    <Minutes year="2024" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2023">
                                                    <Minutes year="2023" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2022">
                                                    <Minutes year="2022" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2021">
                                                    <Minutes year="2021" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2020">
                                                    <Minutes year="2020" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2019">
                                                    <Minutes year="2019" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2018">
                                                    <Minutes year="2018" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2017">
                                                    <Minutes year="2017" />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2016">
                                                    <Minutes year="2016" />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                        <Col sm={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2024">2024</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2023">2023</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2022">2022</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2021">2021</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2020">2020</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2019">2019</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2018">2018</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2017">2017</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2016">2016</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Container>
                        </Col>
                        <Col md={3}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <GoToTop />
        </Container>
    );
}

export default MinutesHome;
