import React from 'react';

function ExternalLink(props) {
    const href = (props.href && props.href.trim()) || '';
    const label = (props.label && props.label.trim()) || '';
    const aClass = (props.className && props.className.trim()) || '';
    return <a href={href} target="_blank" rel="noopener noreferrer" className={aClass}>{label || href}</a>;
}

export default ExternalLink;
