import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ExternalLink from './components/shared/ExternalLink';
import Email from './components/shared/Email';
import Phone from './components/shared/Phone';

function Footer() {
    return (
        <>
            <Container id="footer" fluid className="text-center g-0">
                <Container>
                {/* <Row id="stations" className="justify-content-center">
                    <ListGroup horizontal className="justify-content-center">
                        <ListGroup.Item>
                            <strong>Station 5-1</strong><br />
                            Allyn Fire Station<br />
                            18411 E State Route 3<br />
                            Allyn, WA<br />
                            <small className="text-secondary"><strong>Staffed</strong></small>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-2</strong><br />
                            Victor Fire Station<br />
                            4141 E State Hwy 302<br />
                            Victor, WA
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-3</strong><br />
                            Mason Benson Fire Station<br />
                            2520 E Mason Benson Rd<br />
                            Grapeview, WA
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-4</strong><br />
                            Lake Limerick Fire Station<br />
                            21 E Saint Andrews Dr<br />
                            Shelton, WA<br />
                            <small className="text-secondary"><strong>Staffed</strong></small>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-5</strong><br />
                            Agate Fire Station<br />
                            6011 E Agate Rd<br />
                            Shelton, WA
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup horizontal className="justify-content-center">
                        <ListGroup.Item>
                            <strong>Station 5-6</strong><br />
                            Deer Creek Fire Station<br />
                            21 E Gosser Rd<br />
                            Shelton, WA
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-7</strong><br />
                            Spencer Lake Fire Station<br />
                            1741 E Pickering Rd<br />
                            Shelton, WA<br />
                            <small className="text-secondary"><strong>Staffed</strong></small>
                        </ListGroup.Item>
                        <ListGroup.Item id="hq">
                            <strong>Station 5-8</strong><br />
                            Shelton Fire Station<br />
                            122 W. Franklin St.<br />
                            Shelton, WA 98584<br />
                            <small className="text-secondary"><strong>Staffed Administrative Location</strong></small>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-9</strong><br />
                            Harstine Island North Fire Station<br />
                            3190 E North Island Dr<br />
                            Shelton, WA<br />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-10</strong><br />
                            Johns Prairie Fire Station<br />
                            2610 E Johns Prairie Rd<br />
                            Shelton, WA<br />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Station 5-11</strong><br />
                            Harstine Island South Fire Station<br />
                            2260 E Harstine Island Rd South<br />
                            Shelton, WA
                        </ListGroup.Item>
                    </ListGroup>
                </Row> */}
                {/* <Row>
                    <div class="effect laertes">
                        <div class="buttons">
                            <a href="#" class="fb" title="Join us on Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></Link>
                            <a href="#" class="tw" title="Join us on Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </Row> */}
                <Row id="information">
                    <Col>
                        <div className="justify-content-start">
                            <h5>Central Mason Fire-EMS</h5>
                            <h6>Headquarters</h6>
                            {/* <strong>Station 5-8 Shelton Fire Station</strong><br /> */}
                            <strong>Address:</strong> 122 W. Franklin St.<br />
                            Shelton, WA 98584<br />
                            <strong>Phone:</strong> <Phone number="(360)-426-5533" /> / <Phone number="(360)-275-2889" /><br />
                            <strong>Fax:</strong> <Phone number="(360)-427-9438" isFax /><br />
                            <strong>Hours:</strong> MON-FRI 8AM-4PM
                        </div>
                    </Col>
                    <Col id="quick-links">
                        <h5>Quick Links</h5>
                        <ul>
                            <li><Link to="/commissioners">Board of Commissioners</Link></li>
                            <li><Link to="/about/chiefsmessage">Message from the Chief</Link></li>
                            <li><Link to="/about/documents">Agency Documents</Link></li>
                            <li><Link to="/services/public-records-request">Public Records Request</Link></li>
                        </ul>
                    </Col>
                    <Col id="helpful-links">
                        <h5>Helpful Links</h5>
                        <ul>
                            <li><Link to="/about/mission">Our Mission, Vision, & Values</Link></li>
                            <li><Link to="/about/ourstations">Station Locations</Link></li>
                            <li><Link to="/about/contact">Contact Us</Link></li>
                            {/* <li><Link to="/about/sitemap">Site Map</Link></li> */}
                        </ul>
                    </Col>
                </Row>
                </Container>
                <Row id="social" className="row justify-content-center">
                    {/* <div> */}
                        <Container>
                            <Row className='justify-content-center'>
                                <Col>
                                <ul className="ps-0 pb-2">
                                    <li className="text-secondary">STAY CONNECTED WITH <span>CMFE</span></li>
                                    {/* <li><ExternalLink href="https://twitter.com/" label="Twitter" /></li> */}
                                    <li><ExternalLink href="https://www.facebook.com/CMFE5" label="Facebook" /></li>
                                    <li><Email email="info@cmfe.org" label="Email" /></li>
                                </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                <span className="small fw-bold text-secondary">© Copyright 2021 Central Mason Fire-EMS. All rights reserved.</span>
                                </Col>
                            </Row>
                        </Container>
                    {/* </div> */}
                </Row>
            </Container>
        </>
    );
}

export default Footer;
