import React, { useState } from 'react';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
// import Phone from '../shared/Phone';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function FireAdaptedCommunities() {
    const [filePath, setFilepath] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Container id="fire-adapted-communities" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Fire Adapted Communities" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Fire Adapted Communities Cycle: A Facilitation Guide</h1>
                                <section className="mt-3 mb-3">
                                    {/* <Alert className="text-center p-2 m-3" variant="info">Fire Adapted Communities understand their risk and are taking action to better prepare for, respond to, and recover from wildfire.</Alert>

                                    <p>
                                    The FAC Cycle illustration (below) is a way to start the conversation with individuals, communities, agencies, organizations and partners about what it means to be fire adapted.
                                    </p>
                                    <p><strong>TODO: PUT IMAGE HERE</strong></p>
                                    <h4 className="mb-3">Scenes represented above include:</h4>
                                     */}
                                     
                                     <embed src={process.env.PUBLIC_URL + `/files/Fire-Adapted-Communities.pdf`} frameBorder="0" width="100%" height="680px" />
                                     <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                        setFilepath(`${process.env.PUBLIC_URL}/files/Fire-Adapted-Communities.pdf`);
                                        setModalTitle('Fire Adapted Communities Cycle: A Facilitation Guide');
                                        handleShow();
                                    }}>
                                        View Fire Adapted Communities Cycle
                                    </Button>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={filePath} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FireAdaptedCommunities;
