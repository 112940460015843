import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './custom.scss';
import './App'
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ParallaxProvider } from 'react-scroll-parallax';
import { AppContextProvider } from './AppContext';


const container = document.getElementById('root')
const root = createRoot(container);
root.render(
  <AppContextProvider>
    <ParallaxProvider>
      <App />
    </ParallaxProvider>
  </AppContextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
