import React, { useState } from 'react';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import Phone from '../shared/Phone';
import Email from '../shared/Email';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function CPRFirstAid() {
    const [filePath, setFilepath] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Container id="fire-adapted-communities" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="CPR/First Aid" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>CPR/First Aid Classes</h1>
                                <section className="mt-3 mb-3">
                                    <h2>ASHI CPR, AED, and Basic First Aid Combination Training</h2>
                                    
                                    <p>The ASHI CPR, AED, and Basic First Aid combination training program helps students develop the knowledge, skills and the confidence to respond to a medical emergency. This class is an excellent choice for both the community and workplace setting, and conforms to the 2020 Guidelines Update for CPR, ECC, and First Aid. </p>

                                    <p>Cost is $35.00 per person, cash or check. </p>

                                    <p>
                                    Have a business, school or group?<br />
                                    We do groups at the station and travel within Mason county. <br />Please contact:<br />
                                    <strong>Eunice Randall</strong><br />
                                    <Email email="erandall@cmfe.org" label="erandall@cmfe.org" />
                                    <Phone number="(360)-507-2282 " />
                                    </p>
                                    <section className="text-center">
                                    
                                        {/* <embed src={process.env.PUBLIC_URL + `/files/2024-CPR-class.png`} frameBorder="0" width="80%" />
                                        <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                            setFilepath(`${process.env.PUBLIC_URL}/files/2024-CPR-class.pdf`);
                                            setModalTitle('2024 CPR Class');
                                            handleShow();
                                            }}>
                                            View CPR Class Information
                                        </Button> */}
                                        <embed src={process.env.PUBLIC_URL + `/files/CPR-Flyer.png`} frameBorder="0" width="80%" />
                                        <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                            setFilepath(`${process.env.PUBLIC_URL}/files/CPR-Flyer.pdf`);
                                            setModalTitle('CPR First Aid Classes');
                                            handleShow();
                                            }}>
                                            View CPR/First Aid Classes Information
                                        </Button>

                                        {/* <embed src={process.env.PUBLIC_URL + `/files/CPR-Info-Flyer-2021.png`} frameBorder="0" width="80%" />
                                     <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                        setFilepath(`${process.env.PUBLIC_URL}/files/CPR-Info-Flyer-2021.pdf`);
                                        setModalTitle('CPR First Aid Classes');
                                        handleShow();
                                    }}>
                                        View CPR/First Aid Classes Information
                                    </Button> */}
                                    <hr className="m-5" />
                                    <h2 className="text-uppercase">CPR Fact Sheet</h2>
                                    <embed src={process.env.PUBLIC_URL + `/files/CPR-Fact-sheet.jpg`} frameBorder="0" width="80%" />
                                    <hr className="m-5" />
                                    <h2 className="text-uppercase">CPR Facts</h2>
                                    <embed src={process.env.PUBLIC_URL + `/files/CPR-facts.jpg`} frameBorder="0" width="80%" />
                                    </section>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={filePath} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CPRFirstAid;
