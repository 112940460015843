import React, { useEffect, useRef } from "react";

function BurnBan() {
    const isLifted = useRef(false);

  useEffect(() => {
    const getData = () => {
        fetch("/files/burnban.json")
          .then((response) => response.json())
          .then((response) => {
            isLifted.current = response?.[0]?.lifted;
          });
      };
    getData();
  }, []);

  // Options: LIFTED, Full Burn Ban, Partial Burn Ban
  return (
    <div id="burnban">
      BURN RESTRICTIONS: {isLifted.current}
    </div>
  );
}

export default BurnBan;
