import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../AppContext';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Sidebar from './Sidebar';
import Breadcrumbs from './Breadcrumbs';
import GoToTop from '../GoToTop';

function Forms() {
    const { pages, pagesAreLoading } = useContext(AppContext);
    const [forms, setForms] = useState({});

    useEffect(() => {
        const getForms = () => {
            if (pages) {
                let formsPage = {};
                pages.forEach((page) => {
                    if (page && page.link.indexOf('forms') > -1) {
                      formsPage = page;
                    }
                });
                setForms(formsPage);
            }
        }
  
        if (pages) {
            getForms();
        }
    }, [pages]);

    if (pagesAreLoading) {
      return (
        <>
          <Container id="forms" className="main">
            <ScrollToTopOnMount />
            <Container className="page">
              <Breadcrumbs activePage="Forms" />
              <Row>
                <Col md={9}>
                  <Container>
                    <h1>Forms</h1>
                    <Container className="main align-content-start">
                      <Container>
                        <div className="d-flex justify-content-center mt-5 mb-3">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </Container>
                    </Container>
                  </Container>
                </Col>
                <Col md={3}>
                  <Sidebar />
                </Col>
              </Row>
            </Container>
            <GoToTop />
          </Container>
        </>
      );
    }

    if (!pages) return null;

    return (
      <>
      <Container id="forms" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Forms" />
          <Row>
            <Col md={9}>
              <Container>
                <h1>Forms</h1>
                {forms &&
                forms.content &&
                forms.content.rendered ? (
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: forms.content.rendered,
                    }}
                  />
                ) : null}
              </Container>
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
        <GoToTop />
      </Container>
    </>
    );
}

export default Forms;
