import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import Email from "../shared/Email";

function Commissioners() {
  return (
    <Container className="main">
      <Container className="page">
        <Breadcrumbs activePage="Commissioners" />
        <Container>
          <Row>
            <Col md={9}>
              <h1>Commissioners</h1>
              <section className="mt-3 mb-3">
                <Row className="mt-5">
                  <Col className="text-center">
                    <h5>Steve Pierce - Chair</h5>
                    <div>
                      Email: <Email email="spierce@cmfe.org" />
                    </div>
                  </Col>
                  <Col className="text-center">
                    <h5>Tom Taylor</h5>
                    <div>
                      Email: <Email email="ttaylor@cmfe.org" />
                    </div>
                  </Col>
                  <Col className="text-center">
                    <h5>Pete Butkus</h5>
                    <div>
                      Email: <Email email="pbutkus@cmfe.org" />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    <h5>Kelly Frazier</h5>
                    <div>
                      Email: <Email email="kfrazier@cmfe.org" />
                    </div>
                  </Col>
                  <Col className="text-center">
                    <h5>Kevin Ward</h5>
                    <div>
                      Email: <Email email="kward@cmfe.org" />
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Commissioners;
