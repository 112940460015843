import React from 'react';

function GoToTop () {
    return (
        <div id="go-to-top" className="container-fluid text-center pb-5 g-0">
            <button type="button" className="btn btn-link text-uppercase" onClick={e => window.window.scrollTo(0, 0)}>&#8593; Go to top &#8593;</button>
        </div>
    );
}

export default GoToTop;
