import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function AmbulanceBilling() {
    return (
        <>
            <Container id="ambulance-billing" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Ambulance Billing" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Ambulance Billing</h1>
                                <section className="mt-3 mb-5">
                                    <p>Have ambulance billing questions or concerns? Contact our billing company, Systems Design West at 360-394-7010 or visit them on the web at <ExternalLink href="www.systemsdesignwestems.com" /></p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default AmbulanceBilling;
