import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import Navigation from "./components/Navigation";
import HomeCarousel from "./components/HomeCarousel";
// import AboutUs from "./components/about/About";
import AboutUs from "./components/about/AboutDynamic";
import OurDistrict from "./components/about/OurDistrict";
import Mission from "./components/about/Mission";
import ContactUs from "./components/about/ContactUs";
import OurHistory from "./components/about/OurHistory";
// import CommandStaff from "./components/about/CommandStaff";
import CommandStaff from "./components/about/CommandStaffDynamic";
// import OurStations from "./components/about/OurStations";
import OurStations from "./components/about/OurStationsDynamic";
import MinutesHome from "./components/commissioners/MinutesHome";
import Documents from "./components/about/Documents";
import Forms from "./components/about/Forms";
import ChiefsMessage from "./components/about/ChiefsMessage";
import FireMarshal from "./components/about/FireMarshal";
import FireMarshalFAQ from "./components/about/FireMarshalFAQ";
import Opportunities from "./components/opportunities/Opportunities2";
import Employment from "./components/opportunities/Employment";
import Commissioners from "./components/commissioners/Commissioners";
import Commissioners2 from "./components/about/Commissioners";
import Community from "./components/community/Community";
import Gallery from "./components/community/Gallery";
import BurningRegulations from "./components/community/BurningRegulations";
import SmokeAlarms from "./components/services/SmokeAlarms";
import KnoxBoxes from "./components/services/KnoxBoxes";
import DrivewayMarker from "./components/services/DrivewayMarker";
import StationTours from "./components/services/StationTours";
import AmbulanceBilling from "./components/services/AmbulanceBilling";
import Speakers from "./components/services/Speakers";
import FireExtinguishers from "./components/community/FireExtinguishers";
import Permits from "./components/services/Permits";
import ServicesForms from "./components/services/ServicesForms";
import PublicRecordsRequest from "./components/services/PublicRecordsRequest";
import Exemptions from "./components/services/PublicRecordsRequest/Exemptions";
import IAFFLocal2394Scholarship from "./components/community/IAFFLocal2394Scholarship";
import FireAdaptedCommunities from "./components/community/FireAdaptedCommunities";
import CPRFirstAid from "./components/services/CPRFirstAid";
import CountywideEmergencyAlerts from "./components/services/CountywideEmergencyAlerts";
import ProtectYourHome from "./components/community/ProtectYourHome";
import SafetyInformation from "./components/community/SafetyInformation";
import BurningInformation from "./components/community/BurningInformation";
import ForKids from "./components/community/ForKids";
import Footer from "./Footer";
import Volunteer from "./components/opportunities/Volunteer";
import Post1 from "./components/pages/Post1";
import Post2 from "./components/Post2";
import Covid19 from "./components/covid19/Covid19";
import Header from "./components/header/Header";
import Welcome from "./components/home/Welcome";
import Events from "./components/events/Events";
// import ZoomHelp from "./components/events/ZoomHelp";
import Posts from "./components/pages/Posts";
import { AppContext } from "./AppContext";
import BurningRestrictions from "./components/safety/BurningRestrictions";
import FireLevy from "./components/firelevy/FireLevy";
import MemoFromChiefs from "./components/firelevy/MemoFromChiefs";
import Fees from "./components/services/PublicRecordsRequest/Fees";
import PressReleases from "./components/about/PressReleases";
import Content from "./components/contents/Content";
// import Content from './components/Contents';
import AllContents from "./components/contents/AllContents";
// import FiveMemberBoard from "./components/home/FiveMemberBoardBallot";

function App() {
  const {
    setPostData,
    setPostsAreLoadingCallback,
    setPageData,
    setPagesAreLoadingCallback,
    setContentData,
    setContentsAreLoadingCallback,
    setLatestNewsData,
    setLatestNewsLoadingCallback,
    setPressReleasesData,
    setPressReleasesLoadingCallback,
  } = useContext(AppContext);

  const restApiUrl = "https://cmfe.org/blog/wp-json/wp/v2/";
  const postsUrl = `${restApiUrl}posts`;
  const pagesUrl = `${restApiUrl}pages?per_page=100`;
  const contentUrl = `${restApiUrl}content?per_page=100`;
  const latestNewsUrl = `${restApiUrl}latest-news?per_page=100`;
  const pressReleasesUrl = `${restApiUrl}press-release?per_page=100`;

  useEffect(() => {
    async function fetchPostData() {
      const response = await axios(postsUrl);
      if (response.data) {
        setPostData(response.data);
      }
      setPostsAreLoadingCallback(false);
    }
    async function fetchPageData() {
      const response = await axios(pagesUrl);
      if (response.data) {
        setPageData(response.data);
      }
      setPagesAreLoadingCallback(false);
    }
    async function fetchContentData() {
      const response = await axios(contentUrl);
      if (response.data) {
        setContentData(response.data);
      }
      setContentsAreLoadingCallback(false);
    }
    async function fetchLatestNews() {
      const response = await axios(latestNewsUrl);
      if (response.data) {
        setLatestNewsData(response.data);
      }
      setLatestNewsLoadingCallback(false);
    }
    async function fetchPressReleases() {
      const response = await axios(pressReleasesUrl);
      if (response.data) {
        setPressReleasesData(response.data);
      }
      setPressReleasesLoadingCallback(false);
    }

    fetchPostData();
    fetchPageData();
    fetchContentData();
    fetchLatestNews();
    fetchPressReleases();
  }, [
    setPostData,
    setPostsAreLoadingCallback,
    setPageData,
    setPagesAreLoadingCallback,
    setContentData,
    setContentsAreLoadingCallback,
    setLatestNewsData,
    setLatestNewsLoadingCallback,
    setPressReleasesData,
    setPressReleasesLoadingCallback,
    contentUrl,
    latestNewsUrl,
    pagesUrl,
    postsUrl,
    pressReleasesUrl,
  ]);

  return (
    <Router>
      <Container fluid className="ps-0 pe-0 g-0">
        <Header />
        <Navigation />
        <Routes>
          <Route path="/about/mission" element={<Mission />} />
          <Route path="/about/contact" element={<ContactUs />} />
          <Route path="/about/ourhistory" element={<OurHistory />} />
          <Route path="/about/ourstations" element={<OurStations />} />
          <Route path="/about/ourdistrict" element={<OurDistrict />} />
          <Route path="/about/documents" element={<Documents />} />
          <Route path="/about/forms" element={<Forms />} />
          <Route path="/about/pressreleases" element={<PressReleases />} />
          <Route path="/about/chiefsmessage" element={<ChiefsMessage />} />
          <Route path="/about/commissioners2" element={<Commissioners2 />} />
          <Route path="/about/commandstaff" element={<CommandStaff />} />
          <Route path="/about/firemarshal" element={<FireMarshal />} />
          <Route path="/about/firemarshalfaq" element={<FireMarshalFAQ />} />
          <Route path="/about" element={<AboutUs />} />

          <Route path="/commissioners/events" element={<Events />} />
          <Route path="/commissioners/minutes" element={<MinutesHome />} />
          {/* <Route path="/commissioners/zoom-help" element={<ZoomHelp />} /> */}
          <Route path="/commissioners" element={<Commissioners />} />

          <Route path="/opportunities/employment" element={<Employment  />} />
          <Route path="/opportunities/volunteer" element={<Volunteer  />} />
          <Route path="/opportunities" element={<Opportunities />} />

          <Route path="/community" element={<Community />} />
          {/* <Route path="/community/zoomhelp" element={<ZoomHelp />} /> */}
          <Route path="/community/calendar" element={<Events />} />
          <Route path="/community/gallery" element={<Gallery />} />
          <Route
            path="/community/burningregulations"
            element={<BurningRegulations />}
          />
          <Route
            path="/services/smoke-alarm-installation"
            element={<SmokeAlarms />}
          />
          <Route path="/services/knoxboxes" element={<KnoxBoxes />} />
          <Route
            path="/services/countywide-emergency-alerts"
            element={<CountywideEmergencyAlerts />}
          />
          <Route
            path="/services/ambulance-billing"
            element={<AmbulanceBilling  />}
          />
          <Route path="/services/station-tours" element={<StationTours />} />
          <Route path="/services/drivewaymarker" element={<DrivewayMarker />} />
          <Route path="/services/speakers" element={<Speakers />} />
          <Route
            path="/community/fireextinguishers"
            element={<FireExtinguishers />}
          />
          <Route path="/services/permits" element={<Permits />} />
          <Route
            path="/services/services-and-forms/:type"
            element={<ServicesForms />}
          />
          <Route
            path="/services/public-records-request"
            element={<PublicRecordsRequest />}
          />
          <Route
            path="/public-records-request/exemptions"
            element={<Exemptions />}
          />
          <Route path="/public-records-request/fees" element={<Fees />} />
          <Route
            path="/services/services-and-forms"
            element={<ServicesForms />}
          />
          <Route
            path="/community/iaff-local-2394-scholarship"
            element={<IAFFLocal2394Scholarship />}
          />
          <Route
            path="/community/fire-adapted-communities"
            element={<FireAdaptedCommunities />}
          />
          <Route path="/services/cpr-first-aid" element={<CPRFirstAid />} />
          <Route
            path="/community/protect-your-home"
            element={<ProtectYourHome />}
          />
          <Route
            path="/community/safety-information"
            element={<SafetyInformation />}
          />
          <Route
            path="/community/burning-information"
            element={<BurningInformation />}
          />
          <Route path="/community/for-kids" element={<ForKids />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/latest/:id" element={<Post1 />} />
          <Route path="/latest" element={<Posts />} />
          <Route path="/contents/:id" element={<Content />} />
          <Route path="/contents" element={<AllContents />} />
          <Route path="/post2" element={<Post2 />} />
          <Route path="/covid19" element={<Covid19 />} />
          <Route
            path="/community/burnrestrictions"
            element={<BurningRestrictions />}
          />
          <Route path="/firelevy" element={<FireLevy />} />
          {/* <Route path="/ballot" element={<FiveMemberBoard />} /> */}
          <Route
            path="/firelevy/memofromchiefs"
            element={<MemoFromChiefs />}
          />
          <Route
            path="/"
            element={
              <>
                <HomeCarousel />
                <Welcome />
              </>
            }
          />
          {/* <Route element={<NoMatch />} /> */}
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;
