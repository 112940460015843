import React from 'react';
import NavigationCollapsed from './navigation/NavigationCollapsed';
import NavigationExpanded from './navigation/NavigationExpanded';

function Navigation() {
    return (
        <>
            <NavigationCollapsed />
            <NavigationExpanded />
        </>
    );
}

export default Navigation;
