import React, { useContext, useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { AppContext } from "../../../AppContext";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "../Sidebar";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import GoToTop from "../../GoToTop";

function Fees() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [fees, setFees] = useState({});

  useEffect(() => {
    const getPrqPage = () => {
      if (pages) {
        let prqPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("public-records-request-fee") > -1) {
            prqPage = page;
          }
        });
        setFees(prqPage);
      }
    };

    if (pages) {
      getPrqPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <>
        <Container id="fees" className="main">
          <ScrollToTopOnMount />
          <Container className="page">
            <Breadcrumbs activePage="Public Records Requests" />
            <Row>
              <Col md={9}>
                <Container>
                  <h1>Fees</h1>
                  <Container className="main align-content-start">
                    <Container>
                      <div className="d-flex justify-content-center mt-5 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </Container>
                  </Container>
                </Container>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
          <GoToTop />
        </Container>
      </>
    );
  }

  if (!pages) return null;
  return (
    <>
      <Container id="fees" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Fees" />
          <Container>
            <Row>
              <Col md={9}>
                <h1>Fees</h1>
                <section className="mt-3 mb-5">
                  <>
                    {fees && fees.content && fees.content.rendered ? (
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: fees.content.rendered,
                        }}
                      />
                    ) : null}
                  </>
                </section>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default Fees;
