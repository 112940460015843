import React, { useState, useCallback } from "react";

export const AppContext = React.createContext();

export const AppContextProvider = ({ children }) => {
  const [posts, setPosts] = useState(null);
  const [postsAreLoading, setPostsAreLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const [pagesAreLoading, setPagesAreLoading] = useState(true);
  const [contentData, setContentData] = useState(null);
  const [contentsAreLoading, setContentsAreLoading] = useState(true);
  const [latestNewsData, setLatestNewsData] = useState(null);
  const [latestNewsLoading, setLatestNewsLoading] = useState(true);
  const [pressReleasesData, setPressReleasesData] = useState(null);
  const [pressReleasesLoading, setPressReleasesLoading] = useState(true);

  const setPostData = useCallback(
    (_data) => {
      if (_data) {
        setPosts(_data);
      }
    },
    [setPosts]
  );

  const setPageData = useCallback(
    (_data) => {
      if (_data) {
        setPages(_data);
      }
    },
    [setPages]
  );

  const setPostsAreLoadingCallback = useCallback(
    (value) => {
      setPostsAreLoading(value);
    },
    [setPostsAreLoading]
  );

  const setPagesAreLoadingCallback = useCallback(
    (value) => {
      setPagesAreLoading(value);
    },
    [setPagesAreLoading]
  );

  const setContentsAreLoadingCallback = useCallback(
    (value) => {
      setContentsAreLoading(value);
    },
    [setContentsAreLoading]
  );

  const setLatestNewsLoadingCallback = useCallback(
    (value) => {
        setLatestNewsLoading(value);
    },
    [setLatestNewsLoading]
  );

  const setPressReleasesLoadingCallback = useCallback(
    (value) => {
        setPressReleasesLoading(value);
    },
    [setPressReleasesLoading]
  );

  return (
    <AppContext.Provider
      value={{
        setPostData,
        posts,
        postsAreLoading,
        setPostsAreLoadingCallback,
        setPageData,
        pages,
        pagesAreLoading,
        setPagesAreLoadingCallback,
        setContentData,
        contentData,
        contentsAreLoading,
        setContentsAreLoadingCallback,
        // Latest News
        latestNewsData,
        setLatestNewsData,
        latestNewsLoading,
        setLatestNewsLoadingCallback,

        // Press Releases
        pressReleasesData,
        setPressReleasesData,
        pressReleasesLoading,
        setPressReleasesLoadingCallback,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
