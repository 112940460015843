import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ExternalLink from "../shared/ExternalLink";

function Sidebar() {
//   const { hash, pathname } = useLocation();
//   const path = pathname.split("/");
//   const last = path[path.length - 1];

//   const isLinkActive = (link, sub) => {
//     if (hash && hash.length > 0 && hash.indexOf(sub) > -1) {
//       console.log(link);
//       return true;
//       // } else if (hash.length === 0 && !sub && last.indexOf(link) > -1) {
//     } else if (hash.length === 0 && !sub && last === link) {
//       return true;
//     }
//     return false;
//   };


  return (
    <>
      <div id="sidebar" className="pt-4 pb-4 ps-3 pe-3 mb-3 bg-light shadow-sm">
        <Nav defaultActiveKey="/" className="small flex-column">
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            About CMFE
          </h6>
          <Nav.Link
            as={NavLink}
            end
            to="/about"
            className="main-link"
          >
            About Us
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/mission"
            // className={isLinkActive("mission") ? "active" : ""}
            className="main-link"
          >
            Our Mission, Vision, & Values
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/chiefsmessage"
            className="main-link"
          >
            Fire Chief's Message
          </Nav.Link>

          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Organization
          </h6>
          <Nav.Link
            as={NavLink}
            end
            to="/about/commissioners2"
          >
            Board of Commissioners
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/commandstaff"
          >
            Command Staff
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/firemarshal"
          >
            Fire Marshal's Office
          </Nav.Link>
          <ExternalLink
            href="https://www.co.mason.wa.us/community-services/fire-marshal/index.php"
            label="Mason County Fire Marshal"
            className="nav-link"
          />
          {/* <Nav.Link as={NavLink} end to="/opportunities" className="main-link">Opportunities</Nav.Link> */}
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Opportunities
          </h6>
          <Nav.Link
            as={NavLink}
            end
            to="/opportunities/employment"
          >
            Employment
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/opportunities/volunteer"
          >
            Volunteer
          </Nav.Link>
          {/* <Nav.Link as={NavLink} end to="/about/minutes">Agendas & Minutes</Nav.Link> */}
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Public Information
          </h6>
          {/* <Nav.Link as={NavLink} end to="/about/firemarshalfaq">Fire Marshal FAQ</Nav.Link> */}
          <Nav.Link
            as={NavLink}
            end
            to="/about/documents"
          >
            Documents & Reports
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/forms"
          >
            Forms
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/latest"
          >
            Latest News
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/pressreleases"
          >
            Press Releases
          </Nav.Link>
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Contact
          </h6>
          <Nav.Link
            as={NavLink}
            end
            to="/about/contact"
          >
            Contact Us
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/about/ourstations"
          >
            Station Locations
          </Nav.Link>
        </Nav>
      </div>
    </>
  );
}

export default Sidebar;
