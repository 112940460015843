import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import GoToTop from '../GoToTop';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

function AboutUs() {
    return (
        <Container className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs activePage="Our District" />
                <Container>
                    <Row>
                        <Col md={9}>
                            <h1>Our District</h1>
                            <article>
                                <p>
                                    Mason County Fire Protection District #5 started in 1953 operating out of a single volunteer station in Allyn, WA. As the district grew, we became Central Mason Fire & EMS (CMFE) to better reflect our growing population. Today, CMFE is a combination fire department with both volunteer and career members. We serve the communities along the Highway 3 corridor from the Port of Allyn/Victor in the north, down to include the City of Shelton, and from Harstine Island across to Brockdale/McReavy. This is a coverage area of 163.1 square miles.
                                </p>
                                <p>
                                    Our fire department is the largest and busiest in Mason County as well as one of the busiest on the Olympic Peninsula. In 2018 we acquired the private ambulance company Mason County Medic One to better support the emergency needs of our community. Through mutual-aid agreements we provide primary advanced life support services to the majority of Mason County. Our staffed stations are strategically located throughout the district to provide timely care to everyone.
                                </p>
                                <p>
                                    We are proud of our accomplishments and the people who work hard to make this department successful. Most importantly our accomplishments are measured by the communities and people we serve.
                                </p>
                            </article>
                        </Col>
                        <Col md={3}>
                            <Sidebar />
                        </Col>
                    </Row>

                </Container>
            </Container>
            <GoToTop />
        </Container>
    );
}

export default AboutUs;
