import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
// import { StickyContainer, Sticky } from 'react-sticky';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function Covid19() {
    return (
        <Container id="covid19" className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs />
                <Container>
                    <h1>Novel Coronavirus (COVID-19)</h1>
                    <div className="alert alert-warning m-5" role="alert">
                        For Questions about Coronavirus (COVID-19), please call the <strong>Washington Department of Health Coronavirus hotline: 1-800-525-0127, press #</strong>.
                    </div>
                    <h2>What You Need To Know</h2>
                    <section>
                        <ul>
                            <li><a href="http://www.cmfe.org/blog/wp-content/uploads/2020/03/NovelCoronavirusFactSheet-1.pdf" alt="" target="_blank" rel="noopener noreferrer">COVID-19 Fact Sheet (English)</a></li>
                            <li><a href="http://www.cmfe.org/blog/wp-content/uploads/2020/03/NovelCoronavirusFactSheet-Spanish.pdf" alt="" target="_blank" rel="noopener noreferrer">COVID-19 Fact Sheet (Spanish)</a></li>
                            <li><a href="http://www.cmfe.org/blog/wp-content/uploads/2020/03/COVID-19-FLU-Similarities.pdf" alt="" target="_blank" rel="noopener noreferrer">COVID-19 - Flu Similarities</a></li>
                            <li><a href="http://www.cmfe.org/blog/wp-content/uploads/2020/03/steps-to-prevent-spread-of-COVID.pdf" alt="" target="_blank" rel="noopener noreferrer">Steps to Prevent Spread of COVID-19</a></li>
                        </ul>
                    </section>
                    <section id="posters" className="text-center">
                        <Image
                            src={process.env.PUBLIC_URL + `/files/mason-county-community-services1.jpg`}
                            className="mx-auto d-block mb-3"
                        />
                        <Image
                            src={process.env.PUBLIC_URL + `/files/mason-county-community-services2.jpg`}
                            className="mx-auto d-block mb-3"
                        />
                    </section>
                </Container>
            </Container>
          <GoToTop />
        </Container>
    );
}

export default Covid19;
