import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
// import Breadcrumbs from '../community/Breadcrumbs';
// import Sidebar from '../community/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function DrivewayMarker() {

    return (
        <>
            <Container id="fire-marshal" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Driveway Marker" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Driveway Marker</h1>
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Can we find you?</h4>
                                    <p>When you have an emergency every second counts when we are responding. Have address identification on your house can be crucial to emergency responders having the quickest response possible. </p>
                                    <p>When you’re installing address number identification please remember the following:</p>
                                    <ul>
                                        <li>Make the numbers large enough that they are easily visible from the road</li>
                                        <li>Have the numbers on a contrasting background</li>
                                        <li>If your house is not visible from the road, install a sign or reflective post at the beginning of your driveway</li>
                                    </ul>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Address Marker Program</h4>
                                    <p>If you need help getting an address marker, you can purchase one from us! Fill out the <a href={process.env.PUBLIC_URL + `/files/forms/driveway-marker-form.pdf`} alt={`Address Marker request form pdf file`} target="_blank" rel="noopener noreferrer">Address Marker request form</a> and drop it off at our Downtown fire station (122 W Franklin St.). We will then make and install the address marker for you!</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default DrivewayMarker;
