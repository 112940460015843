import React from 'react';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Email from '../shared/Email';
import GoToTop from '../GoToTop';

function IAFF2394Scholarship() {

    return (
        <>
            <Container id="iaff-2394-scholarship" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="IAFF Local 2394 Scholarship Program" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>IAFF Local 2394 Scholarship Program</h1>
                                <section className="mt-3 mb-3">
                                    <p>The IAFF Local 2394 is dedicated to helping people in the targeted communities of Mason County to attend and succeed in the educational arena. The belief that “every person can make a difference” is echoed throughout the Local’s core belief. IAFF Local 2394 facilitates partnership with Central Mason Fire & EMS to increase academic achievement within our community. For 2021, The IAFF Scholarship Fund’s award ceiling is $2,000 and, the individual maximum award is $1,000. IAFF will award approximately four (4) scholarships, ranging in amounts from $500-$1,000 per award, based on the applicant’s college/university/labor choice.</p>
                                    <Alert className="text-center p-2 m-5" variant="info">NOTE: Scholarship funds will be awarded to the student upon evidence of registration in an accredited higher educational institution.</Alert>

                                    <h4 className="mb-3">Criteria and eligibility (IAFF 2394 Scholarship Fund applicants)</h4>

                                    <ol className="mb-5">
                                        <li>Applicant must live in Mason County Washington or be the dependent of a Mason County Public employee.</li>
                                        <li>Applicant must be a graduating (or graduated) high school senior in the year of the award.</li>
                                        <li>Applicant must have at least a 2.0 grade point average (or a “C” average).</li>
                                        <li>Applicant must demonstrate community involvement, improvement in academics, and desires to attend college/trade schools.</li>
                                    </ol>

                                    <h4 className="mb-3">Criteria and eligibility (returning recipients)</h4>
                                    <ol className="mb-5">
                                        <li>Applicant must be a recipient of the IAFF 2394 Scholarship Fund Program.</li>
                                        <li>Applicant must be currently enrolled in a two-year or four-year educational institution.</li>
                                        <li>Applicant must have at least a 2.0 grade point average (or a “C” average).</li>
                                    </ol>
                                    
                                    <p>Application: <a href={process.env.PUBLIC_URL + `/files/forms/IAFF-Local-2394-Scholarship-Application.pdf`} alt={`IAFF Local 2394 Scholarship Application Form pdf file`} target="_blank" rel="noopener noreferrer">Scholarship Application</a></p>
                                    <p>Please email applications to <Email email="scholarships@iaff2394.org" />.</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default IAFF2394Scholarship;
