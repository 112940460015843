import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import MyCalendar from './Calendar';
import AllEvents from './AllEvents';
// import Breadcrumbs from './Breadcrumbs';
import BreadcrumbsCommunity from '../community/Breadcrumbs';
import BreadcrumbsCommissioners from '../commissioners/Breadcrumbs';

function Events() {
    const [showPrintable, setShowPrintable] = useState(false);
    const [loading, setLoading] = useState(true);

    const togglePrintable = () => {
        setShowPrintable(!showPrintable);
        setLoading(!loading);
    };

    const renderSpinner = () => {
        return (
            <div className="d-flex justify-content-center mb-3">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    };

    const path = useLocation().pathname;
    
    let isCommissioners = false ;
    let isCommunity = false;
    // let zoomLink = '';
    if (path && path.indexOf('commissioners') > -1 ) {
        isCommissioners = true;
        // zoomLink = "/commissioners/zoom-help";
    } else if (path && path.indexOf('community') > -1 ) {
        isCommunity = true;
        // zoomLink = "/community/zoomhelp";
    }
    

    if (loading) {
        renderSpinner();
    }
    return (
        <Container id="events" className="main content-no-padding">
            <ScrollToTopOnMount />
            <Container className="page">
                <Container className="page">
                    {/* <Breadcrumbs activePage="Calendar" /> */}
                    { isCommissioners ? <BreadcrumbsCommissioners activePage="Calendar" /> : null }
                    { isCommunity ? <BreadcrumbsCommunity activePage="Calendar" /> : null }
                    <h1 className="page-heading">Calendar</h1>
                    <div className="container">
                        {/* <div className="alert alert-primary text-center pt-1 pb-1" role="alert">
                            <h4 className="alert-heading mb-0">All events are postponed indefinitely as of April 2, 2020.</h4>
                        </div> */}
                        <div className="row mb-3 g-0">
                            <div className="col-lg-12 col-sm-12">
                                {!showPrintable ? <button type="button" className="btn btn-dark float-end shadow-sm" onClick={() => togglePrintable()}>Show all events</button> : <button type="button" className="btn btn-dark float-end shadow-sm" onClick={() => togglePrintable()} >Show calendar</button>}
                                {/* <i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to={zoomLink} className="text-center small mt-2">Need help joining Zoom meetings?</Link> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                {!showPrintable ? <MyCalendar /> : null}
                                {showPrintable ? <AllEvents /> : null}
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </Container>
    );
}

export default Events;