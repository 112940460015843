import React, { useState } from 'react';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
// import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Email from '../shared/Email';
import Phone from '../shared/Phone';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import BurnBanInfo from '../safety/BurnBanInfo';

function BurningRegulations() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Container id="fire-marshal" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Burning Regulations" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Burning Regulations</h1>
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Recreational Fire Restrictions</h4>
                                    <ul>
                                        <li>These types of fires are for cooking, campfires and ceremonial fires.</li>
                                        <li>These must be in a designed fire pit.</li>
                                        <li>Only Charcoal or seasoned dry firewood can be burned.</li>
                                    </ul>

                                </section>
                                
                                <hr className="m-4" />
                                
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Residential Burning Restrictions</h4>
                                    <ul>
                                        <li>These Fires are outdoor burning of leaves, clippings, pruning and other natural vegetation.</li>
                                        <li>Maximum pile size is four feet in diameter.</li>
                                        <li>A minimum 10-foot firebreak must be constructed around each pile.</li>
                                        <li>20-foot vertical clearance from overhanging branches.</li>
                                        <li>Only one fire per parcel.</li>
                                        <li>Burn Pile must be at least 25 feet from any structure or combustible material.</li>
                                        <li>A person and equipment capable of extinguishing the fire must be in constant attendance at the site while burning.</li>
                                        <li>The fire must not create a nuisance from smoke, obscure visibility on public roads, or endanger life and property.</li>
                                    </ul>

                                </section>
                                <hr className="m-4" />

                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Commercial Burning Restrictions</h4>
                                    <ul>
                                        <li>These are fires burning natural vegetation with pile sizes greater than 4 feet in diameter.</li>
                                        <li>Valid Permit must be onsite while burning.</li>
                                        <li>All materials must originate from the property on which the burning is to occur.</li>
                                        <li>The fire must not create a nuisance from smoke, obscure visibility on public roads, or endanger life and property.</li>
                                        <li>Maximum pile size of 20 feet in diameter.</li>
                                        <li>10-foot firebreak around each pile.</li>
                                        <li>Each pile must be 100 feet from any structure and at least 50 feet from standing timber.</li>
                                        <li>Wind speeds must not be more than 7 MPH.</li>
                                        <li>On site water (300 Gal. Min.) is required between April 15th to October 15th.</li>
                                    </ul>
                                </section>
                                <hr className="m-4" />

                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Burn Restrictions Level</h4>
                                    <ol>
                                        <li>Burning Regulated
                                            <ul>
                                                <li>All Types of Burning Allowed</li>
                                            </ul>
                                        </li>
                                        <li>Burning Restrictions
                                            <ul>
                                                <li>No Land Clearing Fires</li>
                                                <li>No Residential Burning Fires</li>
                                                <li>Recreational Fires May be Allowed</li>
                                            </ul>
                                        </li>
                                        <li>Burn Ban
                                            <ul>
                                                <li>All Outdoor Burning is Prohibited</li>
                                                <li>No Land Clearing Fires</li>
                                                <li>No Residential Burning Fires</li>
                                                <li>No Recreational Fires Allowed</li>
                                            </ul>
                                        </li>
                                    </ol>

                                </section>
                                <hr className="m-4" />

                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Urban Growth Areas per WAC 332-24-205</h4>
                                    <ul>
                                        <li>Recreational Burning Only.</li>
                                        <li>No Commercial Burning is allowed in these areas, you will not be able to get a permit in these areas.</li>
                                        <li>No Land Clearing burning are allowed.</li>
                                        <li>Urban Growth Areas include the City of Shelton, Allyn, John’s Prairie, Capitol Hill, Shelton Springs and Island Lake.</li>
                                        <li>See below for a map of Urban Growth Areas.</li>
                                        <section>
                                        
                                        <Button className="mt-2 mb-2" variant="dark" onClick={handleShow}>
                                            <embed className="shadow-sm" src={process.env.PUBLIC_URL + `/images/masonctyurbangrowtharea.png`} frameBorder="0" width="100%" />
                                        </Button>
                                        </section>
                                    </ul>
                                </section>
                                <hr className="m-4" />

                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">What Can I Burn?</h4>
                                    <p><u>Natural vegetation only</u></p>
                                    <p>State Law prohibits the burning of paper or newspaper, cardboard, household garbage, treated, painted or stained wood, plywood, construction debris, paints, tires, and other rubber products, plastics, asphalt and building materials, chemicals, petroleum products, metal, and dead animals</p>
                                </section>
                            <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Reporting Burn Complaint</h4>
                                    <ul>
                                        <li>MACECOM non-emergency line at <Phone number="360-426-4441" /> or <Phone number="911" /></li>
                                        <li>Central Mason Fire & EMS – <Phone number="360-426-5533" /></li>
                                        <li>ORCAA online complaint - <ExternalLink href="https://www.orcaa.org/public-records/online-complaints/" /></li>
                                        <li>Deputy Fire Marshal Keith Reitz
                                            <ul>
                                                <li>Direct Line: <Phone number="360-462-5020" /></li>
                                                <li>Cell: <Phone number="360-490-9060" /></li>
                                                <li>E-Mail: <Email email="kreitz@cmfe.org" /></li>
                                            </ul>
                                        </li>
                                        <section>
                                        
                                        <Button className="mt-2 mb-2" variant="dark" onClick={handleShow}>
                                            <embed className="shadow-sm" src={process.env.PUBLIC_URL + `/images/masonctyurbangrowtharea.png`} frameBorder="0" width="100%" />
                                        </Button>
                                        </section>
                                    </ul>
                                </section>
                                <hr className="m-4" />
                                <BurnBanInfo />
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Map of Mason County Urban Growth Areas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={process.env.PUBLIC_URL + `/images/masonctyurbangrowtharea.png`} frameBorder="0" width="100%" />
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default BurningRegulations;
