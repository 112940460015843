import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Phone from '../shared/Phone';

function Speakers() {
    return (
        <>
            <Container id="speakers" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Speakers" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Speakers</h1>
                                <section className="mt-3 mb-5">
                                    <p>Would you like a speaker from the fire department at your next meeting? We can talk about subjects such as wildfire safety, winter fire safety, home fire safety, etc. Call <Phone number="360-426-5533" /> to schedule a speaker.</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default Speakers;
