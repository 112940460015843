import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { AppContext } from "../../AppContext";

function Latest() {
  const { latestNewsData, latestNewsLoading } = useContext(AppContext);

  if (latestNewsLoading) {
    return (
      <>
        <Container className="main align-content-start">
          <Container>
            <div className="d-flex justify-content-center mt-5 mb-3">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </Container>
        </Container>
      </>
    );
  }

  if (!latestNewsData) return null;

  return (
    <>
      <section className="border-shadow text-center">
        <h5>LATEST NEWS</h5>
        {latestNewsData.map((latest, index) => {
          if (latest && latest.content && latest.content.rendered) {
            return (
              <div
                className="alert alert-info ms-2 me-2"
                key={index}
                role="alert"
              >
                <h6
                  dangerouslySetInnerHTML={{ __html: latest.title.rendered }}
                />
                <span
                  dangerouslySetInnerHTML={{ __html: latest.content.rendered }}
                />
              </div>
            );
          }
          return null;
        })}
      </section>
    </>
  );
}

export default Latest;
