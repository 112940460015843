import React from 'react';
import { Container } from 'react-bootstrap';

function BurnBan() {
    return (
        <Container className="main">
            <div>
                <p>
                    For Burn Ban information and updates in Mason County, please visit <a href="https://masoncountywa.gov/community-services/fire-marshal/burn-restrictions.php" target="_blank" rel="noopener noreferrer">Mason County’s Burn Restriction Page</a>.

                    <br /><br />
                    For Information on Fireworks discharge in Mason County (not including City of Shelton), please visit:<br />
                    <a href="https://www.co.mason.wa.us/community-services/fire-marshal/fireworks.php" target="_blank" rel="noopener noreferrer">https://www.co.mason.wa.us/community-services/fire-marshal/fireworks.php</a>.

                    <br /><br />
                    Burning of debris and fireworks discharge within the Shelton City limits is always prohibited! For more information, please visit <a href="https://www.sheltonwa.gov/departments/fire_department_(central_mason_fire_and_ems)/burn_information.php" target="_blank" rel="noopener noreferrer">the City of Shelton’s Burn Information Page</a>.
                </p>
            </div>
        </Container>
    );
}

export default BurnBan;
