import React from 'react';
import { Container, Image } from 'react-bootstrap';
// import RelatedContent from './RelatedContent';
import News3 from './News3';

function Post() {
    return (
        <Container className="main align-content-start">
            {/* <Breadcrumbs activePage="Events" /> */}
            <Container className="article-content">
                <h1>Coronavirus – What We Actually Know</h1>
                <div className="d-flex">
                    <span className="me-3">
                        <a href="https://www.cbc.ca/player/play/1692308547718" alt="" target="_blank" rel="noopener noreferrer">
                            <Image
                            src={process.env.PUBLIC_URL + `/images/peterlin.jpg`}
                            rounded
                            height={200}
                            className="mx-auto d-block mb-2 me-2 p-2"
                            />
                        </a>
                    </span>
                    <article>
                        <div className="d-flex">
                            <small className="me-2 author text-secondary"><strong>Author:</strong> CMFE</small>
                            <small className="me-2 date text-secondary"><strong>Last Updated:</strong> March 03, 2020</small>
                            <small className="tag text-secondary"><strong>Tag:</strong> Uncategorized</small>
                        </div>
                        <p className="mt-3">
                            Dr. Peter Lin Talks to CBC News about what we actually know about the coronavirus.
                        </p>
                        <p>
                            <a href="https://www.cbc.ca/player/play/1692308547718" alt="" target="_blank" rel="noopener noreferrer">Watch the video</a> on CBC News' website.
                        </p>
                    </article>
                </div>
            </Container>
            {/* <RelatedContent /> */}
            <Container>
                <div className="row justify-content-center p-5">
                <h4 className="text-uppercase">Check Out These Other Posts</h4>
                    <Container>
                        <News3 />
                    </Container>
                </div>
            </Container>
        </Container>
    );
}

export default Post;
