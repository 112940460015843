import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import NavigationLinks from './NavigationLinks';

function NavigationExpanded () {
    return (
        <Navbar id="expanded" bg="dark" variant="dark" expand="lg" className="d-none d-lg-block shadow-sm">
            <Container>
                <Navbar.Brand as={NavLink} to="/">
                CMFE
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <NavigationLinks />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationExpanded;
