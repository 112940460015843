import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

function Post({ post }) {
    const [authorName, setAuthorName] = useState('');
    const [categories, setCategories] = useState('');

    useEffect(() => {
        async function loadData() {
            if (post) {
                let author;
                const categoriesArray = [];

                const authorId = post.author;
                const authorUrl = `https://cmfe.org/blog/wp-json/wp/v2/users/${authorId}`;
                const getAuthor = axios(authorUrl);

                const getCategoriesArray = [];
                const categoryIds = post.categories;

                categoryIds.forEach((categoryId) => {
                    const categoriesUrl = `https://cmfe.org/blog/wp-json/wp/v2/categories/${categoryId}`;
                    const getCategories = axios(categoriesUrl);
                    getCategoriesArray.push(getCategories);
                });

                axios.all([getAuthor, ...getCategoriesArray])
                .then(axios.spread((...responses) => {
                    const authorResponse = responses[0];
                    author = authorResponse.data;
                    setAuthorName(author.name);

                    const categoriesCount = getCategoriesArray.length + 1;
                    for (let index = 1; index < categoriesCount; index += 1) {
                        const categoriesResponse = responses[index];
                        categoriesArray.push(categoriesResponse.data.name);
                    }

                    if (categoriesArray && categoriesArray.length > 0) {
                        setCategories(categoriesArray.join(', '));
                    }
                })).catch(errors => {
                    console.log(errors);
                });
            }
        }
    
        loadData();
    }, [post]);

    if (!post) return null;

    const pathname = `/latest/${post.id}`;

    return (
        <>
            <NavLink to={{ pathname }}><h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} /></NavLink>
            <div className="d-flex">
                <article style={{ width: "100%", overflow: "auto" }}>
                    <div className="post-meta d-flex">
                        { authorName? <small className="me-2 author text-secondary"><strong>Author:</strong> {authorName}</small> : null }
                        { post.modified ? <small className="me-2 date text-secondary"><strong>Last Updated:</strong> {moment(post.modified).format("dddd, MMMM Do YYYY, h:mm:ss a")}</small> : null }
                        { categories ? <small className="tag text-secondary"><strong>Tag:</strong> {categories}</small> : null }
                    </div>
                    <div
                        className="post-content mt-3"
                        dangerouslySetInnerHTML={{__html: post.content.rendered}}
                    />
                </article>
            </div>
        </>
    );
}

export default Post;
