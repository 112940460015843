import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import BurnBanInfo from '../safety/BurnBanInfo';

function SafetyInformation() {
    return (
        <>
            <Container id="safety-information" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Burning Information" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Burning Information</h1>
                                <section className="mt-3 mb-3">
                                    {/* <Alert className="text-center p-2 m-3" variant="warning">The Olympic Region Clean Air Agency (ORCAA) and other clean air agencies including the Department of Ecology (DOE) is asking for a voluntary reduction in outdoor burning at this time. <ExternalLink href="https://www.orcaa.org/good-neighbors-working-together/" label="Click here" /> for more information.</Alert> */}
                                    <p>The regulations for Outdoor Burning vary from the City of Shelton to rural Mason County. Please check to make sure you don’t live in an Urban Growth Area where burning is prohibited and review the maps and regulations for your area by clicking the provided links:</p>
                                    <ul>
                                        <li><ExternalLink href="https://www.co.mason.wa.us/gis/maps/Urban_Growth_Areas_36x48.pdf" label="Urban Growth Area Map" />
                                        </li>
                                        <li><ExternalLink href="http://www.sheltonwa.gov/departments/fire_department_(central_mason_fire_and_ems)/burn_information.php" label="City of Shelton" /></li>
                                        <li><ExternalLink href="https://www.co.mason.wa.us/forms/Community_Dev/outdoor_burning_guidelines.pdf" label="Guidelines for Outdoor Burning in Mason County" /></li>
                                        <li><ExternalLink href="https://www.co.mason.wa.us/community-services/fire-marshal/recreational-burning.php" label="Recreational Outdoor Burning in Mason County" /></li>
                                        <li><ExternalLink href="http://cmfe.northwesthosting.org/wp-content/uploads/2019/10/residential-vs-recreational.pdf" label="Residential VS. Recreational Burning" /></li>
                                    </ul>
                                </section>
                                <BurnBanInfo />
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default SafetyInformation;
