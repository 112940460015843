import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "../Sidebar";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import GoToTop from "../../GoToTop";

function Exemptions() {
  return (
    <>
      <Container id="exemptions" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Exemptions" />
          <Container>
            <Row>
              <Col md={9}>
                <h1>Exemptions</h1>
                <section className="mt-3 mb-5">
                  <p>
                    To promote transparency and responsibility to all citizens,
                    Central Mason Fire & EMS aims to provide full access to
                    public records in accordance with the Washington State
                    Public Records Act.
                  </p>
                  <p>Many records can be found on our website, www.cmfe.org.</p>
                  <p>
                    However, some records are not automatically posted for
                    various reasons, but many can be obtained through a public
                    records request. Some records are exempt in whole or in part
                    in accordance with RCW 42.56.
                  </p>
                  <p>
                    For a complete list of exemptions please see the following:
                  </p>
                  <a
                    className="mx-auto d-block mt-3"
                    href="https://agportal-s3bucket.s3.amazonaws.com/uploadedfiles/Public%20Disclosure%20Exemptions%202022.pdf"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Washington State Office of the Attorney General Sunshine
                    Committee
                  </a>
                  or
                  <a
                    className="mx-auto d-block"
                    href="https://mrsc.org/getmedia/796a2402-9ad4-4bde-a221-0d6814ef6edc/Public-Records-Act.pdf.aspx?ext=.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MRSC Empowering Local Governments
                  </a>
                </section>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default Exemptions;
