import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Email from '../shared/Email';

function ContactUs() {
    return (
        <Container id="contact" className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs activePage="Contact Us" />
                <Container>
                    <Row>
                        <Col md={9}>
                            <h1>Contact Us</h1>
                            <div className="alert alert-warning text-center" role="alert">
                                This site is NOT monitored 24/7 and should not be used for emergencies.
                            </div>
                            {/* <Alert className="text-center p-2 m-3" variant="warning">If you need help installing or getting smoke detectors call the Fire Marshal’s Office at <Phone number="360-462-5020" />.</Alert> */}
                            <h4 className="text-uppercase mt-5 mb-3">Command Staff & Administrative Staff</h4>
                            <div className="locations-container">
                                {/* <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Mike Patti</h5>
                                        <h6 className="mb-1 pb-2">Fire Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="mpatti@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div> */}
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Jeff Snyder</h5>
                                        <h6 className="mb-1 pb-2">Fire Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="jsnyder@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Greg Yates</h5>
                                        <h6 className="mb-1 pb-2">Deputy Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="gyates@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Brandon Searles</h5>
                                        <h6 className="mb-1 pb-2">Division Chief of Logistics</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="bsearles@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">K.C. Whitehouse</h5>
                                        <h6 className="mb-1 pb-2">Battalion Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="kwhitehouse@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Samantha Fox</h5>
                                        <h6 className="mb-1 pb-2">Battalion Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="sfox@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Abel Deffinbaugh</h5>
                                        <h6 className="mb-1 pb-2">Battalion Chief</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="adeffinbaugh@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Norma Brock</h5>
                                        <h6 className="mb-1 pb-2">Executive Secretary</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="nbrock@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Mandy Curtis</h5>
                                        <h6 className="mb-1 pb-2">Administrative Assistant</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="mcurtis@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Angie Zambrano</h5>
                                        <h6 className="mb-1 pb-2">Records Specialist</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="azambrano@cmfe.org" /><br /><br />
                                                        <strong>NOTE: All records requests must be submitted to <Email email="info@cmfe.org" /></strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Keith Reitz</h5>
                                        <h6 className="mb-1 pb-2">Fire Marshal</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="kreitz@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="location">
                                    <div className="location-preview">
                                        <h5 className="mb-0">Matt Heinrich</h5>
                                        <h6 className="mb-1 pb-2">Deputy Fire Marshal</h6>
                                    </div>
                                    <div className="location-info">
                                        <span className="label">Contact Information</span>
                                        <Container className="mt-2 ps-0 pe-0">
                                            <Row>
                                                <Col>
                                                    <h6>Email</h6>
                                                    <p>
                                                        <Email email="mheinrich@cmfe.org" />
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <Sidebar />
                            {/* <iframe
                            title="Our stations on Google Maps"
                            width="600"
                            height="450"
                            frameborder="0" style={{"border":0}}
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCLwLc7g72rdQH62ytyXC7hgV0va4BsyEY&q=Space+Needle,Seattle+WA" allowfullscreen>
                            </iframe> */}
                            <iframe className="shadow-sm" title="Our stations on Google Maps" src="https://www.google.com/maps/d/u/0/embed?mid=10s4igIkSVJYfLQ_4YJhMJ-ZM9sioAniN" style={{ width: '100%', height: '300px', border: '0' }}></iframe>
                        </Col>
                    </Row>

                </Container>
            </Container>
            <GoToTop />
        </Container>
    );
}

export default ContactUs;
