import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppContext } from '../../AppContext';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Post from './Post';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from '../about/Sidebar';

function Posts() {
    const { posts, postsAreLoading } = useContext(AppContext);

    if (postsAreLoading) {
        return (
            <>
                <Container className="main align-content-start">
                    <ScrollToTopOnMount />
                        <Container className="page article-content posts">
                            <Breadcrumbs />
                            <Container>
                            <Row>
                                <Col md={9}>
                                <div className="d-flex justify-content-center mt-5 mb-3">
                                    <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                </Col>
                                <Col md={3}>
                                    <Sidebar />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <GoToTop />
                </Container>
            </>
            
        );
    }
    if (!posts) return null;

    return (
        <>
            <Container className="main align-content-start">
                <ScrollToTopOnMount />
                <Container className="page article-content posts">
                    <Breadcrumbs />
                    <Container className="mb-5">
                        <Row>
                            <Col md={9}>
                                {posts.map((post, index) => (
                                    <article key={post.id}>
                                        <Post post={post} />
                                        { index < posts.length-1 ? <hr className="mt-5 mb-5" /> : null }
                                    </article>
                                ))}
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                    <GoToTop />
                </Container>
            </Container>
        </>
     );
}

export default Posts;
